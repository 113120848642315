main {
  margin: 0px 50px 50px;
}

/* montserrat-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../public/fonts/montserrat-v29-latin-200.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/montserrat-v29-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/montserrat-v29-latin-200.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../public/fonts/montserrat-v29-latin-200.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../public/fonts/montserrat-v29-latin-200.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../public/fonts/montserrat-v29-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/montserrat-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/montserrat-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/montserrat-v29-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../public/fonts/montserrat-v29-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../public/fonts/montserrat-v29-latin-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../public/fonts/montserrat-v29-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/montserrat-v29-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/montserrat-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/montserrat-v29-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../public/fonts/montserrat-v29-latin-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../public/fonts/montserrat-v29-latin-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../public/fonts/montserrat-v29-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../public/fonts/montserrat-v29-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/montserrat-v29-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/montserrat-v29-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../public/fonts/montserrat-v29-latin-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../public/fonts/montserrat-v29-latin-600.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../public/fonts/montserrat-v29-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/montserrat-v29-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/montserrat-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/montserrat-v29-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../public/fonts/montserrat-v29-latin-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../public/fonts/montserrat-v29-latin-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../public/fonts/montserrat-v29-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../public/fonts/montserrat-v29-latin-900.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/montserrat-v29-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/montserrat-v29-latin-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../public/fonts/montserrat-v29-latin-900.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../public/fonts/montserrat-v29-latin-900.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../public/fonts/montserrat-v29-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
